import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/1440p_Series_CGI_List/NavButtons';
export const _frontmatter = {
  "title": "1440p CGI Commands",
  "path": "/1440p_Series_CGI_List/System_Menu/System_Log/",
  "dateChanged": "2018-03-23",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_1080p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "1440p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "the-system-menu--log",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-system-menu--log",
        "aria-label": "the system menu  log permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The System Menu :: Log`}</h1>
    <EuiSpacer mdxType="EuiSpacer" />
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-system-menu--log"
        }}>{`The System Menu :: Log`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetlogattr"
            }}>{`param.cgi?cmd=getlogattr`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-system-log-settings"
                }}>{`Get System Log Settings`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetfilelogattr"
            }}>{`param.cgi?cmd=getfilelogattr`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-system-log-settings-1"
                }}>{`Get System Log Settings`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-1"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetsyslog"
            }}>{`param.cgi?cmd=getsyslog`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-system-log-as-parameter"
                }}>{`Get System Log as Parameter`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-2"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#syslogcgi"
            }}>{`syslog.cgi`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-system-log"
                }}>{`Get System Log`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-3"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdcleanlog"
            }}>{`param.cgi?cmd=cleanlog`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#clean-system-log"
                }}>{`Clean system log`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-4"
                }}>{`Example`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <hr></hr>
    <h2 {...{
      "id": "paramcgicmdgetlogattr",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetlogattr",
        "aria-label": "paramcgicmdgetlogattr permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getlogattr`}</h2>
    <h3 {...{
      "id": "get-system-log-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-system-log-settings",
        "aria-label": "get system log settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get System Log Settings`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`logip`}</code>{`: Activate IP logging of active user `}{`[0,1]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`max_lines`}</code>{`: Max. log length in lines `}{`[20-3000]`}</li>
    </ul>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getlogattr
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getlogattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`logip`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`max_lines`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1000"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setlogattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`logip`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`max_lines`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3000`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"setlogattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetfilelogattr",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetfilelogattr",
        "aria-label": "paramcgicmdgetfilelogattr permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getfilelogattr`}</h2>
    <h3 {...{
      "id": "get-system-log-file-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-system-log-file-settings",
        "aria-label": "get system log file settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get System Log File Settings`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`enable`}</code>{`: Activate system log output `}{`[0,1]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`sdlog`}</code>{`: Store log file to SD card `}{`[0,1]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`level`}</code>{`: Set output verbosity `}{`[`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`debug`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`info`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`warn`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`error`}</code>{`]`}</li>
    </ul>
    <h3 {...{
      "id": "example-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-1",
        "aria-label": "example 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getfilelogattr

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getfilelogattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`sdlog`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`level`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"debug"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setfilelogattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`level`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`error

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"setfilelogattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetsyslog",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetsyslog",
        "aria-label": "paramcgicmdgetsyslog permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getsyslog`}</h2>
    <h3 {...{
      "id": "get-system-log-as-parameter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-system-log-as-parameter",
        "aria-label": "get system log as parameter permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get System Log as Parameter`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`log`}</code>{`: System Log as Parameter`}</li>
    </ul>
    <h3 {...{
      "id": "example-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-2",
        "aria-label": "example 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getsyslog
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getsyslog"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2022-04-21 12:25:25: Startup system
2022-04-21 13:14:24: admin has logged in from 192.168.2.112
2022-04-21 14:13:44: Switching to night mode
2022-04-22 01:53:01: Switching to day mode
2022-04-22 05:26:43: admin has logged in from 192.168.2.112
"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "syslogcgi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#syslogcgi",
        "aria-label": "syslogcgi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`syslog.cgi`}</h2>
    <h3 {...{
      "id": "get-system-log",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-system-log",
        "aria-label": "get system log permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get System Log`}</h3>
    <h3 {...{
      "id": "example-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-3",
        "aria-label": "example 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/syslog.cgi

`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-04-21 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{`:25:25: Startup system
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-04-21 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`13`}</span>{`:14:24: admin has logged `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` from `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.112
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-04-21 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{`:13:44: Switching to night mode
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-04-22 01:53:01: Switching to day mode
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{`-04-22 05:26:43: admin has logged `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` from `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.112`}</code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdcleanlog",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdcleanlog",
        "aria-label": "paramcgicmdcleanlog permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=cleanlog`}</h2>
    <h3 {...{
      "id": "clean-system-log",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#clean-system-log",
        "aria-label": "clean system log permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Clean system log`}</h3>
    <h3 {...{
      "id": "example-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-4",
        "aria-label": "example 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`cleanlog

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cleanlog"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      